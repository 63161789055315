<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>

    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/demand' }"
              >需方市场</el-breadcrumb-item
            >
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618"
              >发布需求</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
    </div>

    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <section class="bgfff" style="padding: 20px 30px">
          <div class="dis align_center">
            <div class="icon"></div>
            <div class="size_18 marl_20">发布采购需求</div>
          </div>
        </section>

        <div
          style="
            background-color: #fff;
            padding: 0px 30px;
            padding-bottom: 20px;
            margin-bottom: 30px;
          "
        >
          <el-form
            :label-position="labelPosition"
            label-width="200px"
            :model="formLabelAlign"
            :rules="rules"
            ref="formLabelAlign"
          >
            <el-form-item label="需求产品" prop="demand_name">
              <el-input
                class="width_500"
                placeholder="请输入需求产品"
                v-model="formLabelAlign.demand_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="行业类型" prop="industry_id">
              <!-- <el-input
                class="width_500"
                v-model="formLabelAlign.industry_type"
              ></el-input> -->
              <el-radio-group
                @change="changeradio(formLabelAlign)"
                v-model="formLabelAlign.industry_id"
              >
                <el-radio
                  v-for="(item, index) in industry"
                  :key="index"
                  v-model="formLabelAlign.radio"
                  :label="item.id"
                  >{{ item.name }}
                  <el-input
                    v-if="industry.length - 1 === index"
                    v-model="formLabelAlign.other_name"
                    :disabled="indexs != 5"
                    class="service_inp"
                    placeholder="无"
                  ></el-input>
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="采购地" prop="city_id">
              <!-- <el-input
                class="width_500"
                v-model="formLabelAlign.num"
              ></el-input> -->

              <el-select
                class="width_500"
                v-model="formLabelAlign.city_id"
                placeholder="请选择采购地"
              >
                <el-option
                  v-for="item in optionPurchase"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否可在本地区内或者省内采购" prop="is_area">
              <!-- <el-input
                class="width_500"
                v-model="formLabelAlign.price"
              ></el-input> -->
              <el-select
                class="width_500"
                v-model="formLabelAlign.is_area"
                placeholder="请选择是否可在本地区内或者省内采购"
              >
                <el-option
                  v-for="item in optionNum"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="采购物名称" prop="purchase_name">
              <el-input
                placeholder="请输入采购物名称"
                class="width_500"
                v-model="formLabelAlign.purchase_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="规格及型号" prop="specs">
              <el-input
                class="width_500"
                placeholder="请输入规格及型号"
                v-model="formLabelAlign.specs"
              ></el-input>
            </el-form-item>
            <el-form-item label="采购数量" prop="purchase_num">
              <el-input
                class="width_500"
                placeholder="请输入采购数量"
                v-model="formLabelAlign.purchase_num"
              ></el-input>
            </el-form-item>
            <el-form-item label="响应时限" prop="deadline">
              <el-select
                class="width_500"
                v-model="formLabelAlign.deadline"
                placeholder="请选择响应时限"
              >
                <el-option
                  v-for="item in optionTime"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系人" prop="contacts">
              <el-input
                class="width_500"
                placeholder="请输入联系人"
                v-model="formLabelAlign.contacts"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                placeholder="请输入联系电话"
                class="width_500"
                v-model="formLabelAlign.phone"
              ></el-input>
            </el-form-item>
            <el-form-item label="需求描述" prop="content">
              <el-input
                type="textarea"
                :rows="5"
                placeholder="请输入需求描述"
                class="width_500"
                v-model="formLabelAlign.content"
              ></el-input>
            </el-form-item>
            <app-serve
              @handleChange="handleChange"
              :checkeding="checkeding"
            ></app-serve>
            <el-button
              @click="addproduct()"
              style="
                background-color: #ff7129;
                color: #fefefe;
                margin-top: 30px;
              "
              >确认添加</el-button
            >

            <el-button
              style="
                background-color: #cfcfcf;
                color: #fefefe;
                margin-top: 30px;
              "
              @click="balck()"
              >取消</el-button
            >
          </el-form>
        </div>
      </div>
      <app-footer />
      <!-- <app-footer @phone="getPhone" /> -->
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import Servetext from "@/components/servetext/servetext.vue";
export default {
  data() {
    return {
      labelPosition: "left",
      offsetHeight: "",
      checkeding: false,
      indexs: "",
      toolbarConfig: {},
      industry: [
        { id: 1, name: "原材料" },
        { id: 2, name: "辅料" },
        { id: 3, name: "包装材料" },
        { id: 4, name: "其他生产要素" },
        { id: 5, name: "其他" },
      ],
      // optionPurchase: [
      //   { value: 0, label: "省内" },
      //   { value: 1, label: "省外" },
      // ],
      optionPurchase: [],
      optionNum: [
        { value: 1, label: "是" },
        { value: 2, label: "否" },
      ],
      optionTime: [
        { value: 1, label: "长期有效" },
        { value: 2, label: "普通" },
        { value: 3, label: "加急（15天以内）" },
        { value: 4, label: "特急（7天以内）" },
      ],

      formLabelAlign: {
        radio: "",
        demand_name: "",
        industry_id: "",
        other_name: "",
        deadline: "",
        // price: "",
        time: "",
        contacts: "",
        phone: "",
        industry_type: "",
        content: "",
        purchase_num: "",
        is_area: "",
        purchase_name: "",
        specs: "",
      },
      rules: {
        demand_name: [
          { required: true, message: "请输入需求名称", trigger: "blur" },
          { required: true, message: "需求名称不能为空", trigger: "change" },
        ],
        industry_id: [
          { required: true, message: "请选择行业类型", trigger: "blur" },
          { required: true, message: "行业类型不能为空", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请输入响应时限", trigger: "blur" },
          { required: true, message: "响应时限不能为空", trigger: "change" },
        ],

        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { required: true, message: "联系人不能为空", trigger: "change" },
        ],
        city_id: [{ required: true, message: "请选择采购地" }],
        is_area: [
          { required: true, message: "请选择是否可在本地区内或者省内采购" },
        ],
        purchase_name: [
          { required: true, message: "请输入采购物名称", trigger: "blur" },
          { required: true, message: "采购物名称不能为空", trigger: "change" },
        ],
        specs: [
          { required: true, message: "请输入规格及型号", trigger: "blur" },
          { required: true, message: "规格及型号不能为空", trigger: "change" },
        ],
        purchase_num: [
          { required: true, message: "请输入采购数量", trigger: "blur" },
          { required: true, message: "采购数量不能为空", trigger: "change" },
        ],

        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { required: true, message: "联系人不能为空", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入需求描述", trigger: "blur" },
          { required: true, message: "需求描述不能为空", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getcity();
  },
  methods: {
    //服务说明
    handleChange(item) {
      this.checkeding = item;
      console.log(this.checkeding, "this.checkeding");
    },
    balck() {
      this.$router.push("/demand");
    },
    getcity() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("area_select", data).then((res) => {
        console.log(res, "re789s");
        this.optionPurchase = res.result.map((item) => {
          return { value: item.id, label: item.name };
        });
      });
    },
    addproduct() {
      if (this.checkeding === false) {
        this.$message({
          message: "请阅读服务说明",
          type: "warning",
          duration: 1500,
        });
        return;
      }
      this.$refs["formLabelAlign"].validate((valid) => {
        if (valid) {
          let data = {
            token: localStorage.eleToken,
            demand_name: this.formLabelAlign.demand_name,
            industry_id: this.formLabelAlign.industry_id,
            other_name: this.formLabelAlign.other_name,
            deadline: this.formLabelAlign.deadline,
            // price: this.formLabelAlign.price,
            contacts: this.formLabelAlign.contacts,
            phone: this.formLabelAlign.phone,
            is_area: this.formLabelAlign.is_area,
            purchase_num: this.formLabelAlign.purchase_num,
            // is_area: this.formLabelAlign.is_area,
            purchase_name: this.formLabelAlign.purchase_name,
            city_id: this.formLabelAlign.city_id,
            specs: this.formLabelAlign.specs,
            content: this.formLabelAlign.content,
            type: 1,
          };
          console.log(data, "data");

          this.$post("demand_add", data).then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
            });
            this.$router.push("/demand");
          });
        }
      });
    },
    changeradio(item) {
      this.indexs = item.industry_id;
      this.formLabelAlign.other_name = "";
      console.log(this.indexs, "this.indexs");
    },
    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-serve": Servetext,
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}
.width_500 {
  width: 500px;
}
.service_inp {
  width: 200px;
  height: 40px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
}
</style>