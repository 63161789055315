<template>
  <div>
    <div style="margin-top: 20px">
      
      <el-checkbox
        v-model="checked"
        size="medium "
        @input="changeParentName()"
      ></el-checkbox>
      <span class="size_18 marl_12"
        >我已阅读
        <span class="useragreement" @click="releasefeedlog = true"
          >服务说明</span
        >
        并同意
      </span>
    </div>
    <el-dialog
      title="青企链”青海省工业中小企业供应链平台服务声明"
      :visible.sync="releasefeedlog"
      width="600px"
      center
    >
      <div style="height: 600px; overflow: auto; color: #222222">
        <div>
          欢迎您注册并使用“青企链”-青海省工业中小企业供应链平台（以下简称：“青企链”平台或本平台）的网络服务。在您使用“青企链”平台之前，请您仔细阅读本平台的全部使用条款，特别是涉及免除或者责任限制的条款，该类条款可能以加粗字体或加下划线的形式提示您重点注意。
        </div>
        <div>
          <div class="weight">一、 接受条款</div>
          <span class="weight"
            >以任何方式进入“青企链”平台即表示您同意平台颁布的一切规章制度和管理办法，且将受本平台各项规章制度及管理办法的约束。平台可随时自行全权决定更改各项规章制度及管理办法的内容。</span
          >
          如有任何变更，平台将在其网站上刊载公告。如您不同意相关变更，必须停止使用。规章制度和管理办法一经在平台公布后，立即自动生效。当服务使用人在第一次登录后应仔细阅读规章制度及管理办法内容，并有权选择停止使用或者继续使用平台内的所有服务；一旦您继续使用了，则表示您已接受经平台对外发布的各项规章制度及管理办法，当用户与平台发生争议时，应以最新的规章制度和管理办法内容为准。
          <span class="weight"
            >除另行明确声明外，任何使平台服务范围扩大或功能增强的新内容均受本管理办法的约束。</span
          >
        </div>
        <div>
          <div class="weight">二、服务使用人</div>
          平台仅供能够根据相关法律订立具有法律约束力的合约的个人或公司使用。
          <span class="weight"
            >因此作为个人，您的年龄必须在十八周岁或以上，才可使用本平台服务。
          </span>
          如不符合本项条件，请勿使用平台。平台可随时自行全权决定拒绝向任何人士提供服务，不会提供给被暂时或永久中止资格的平台用户。
        </div>
        <div>
          <div class="weight">三、服务内容</div>

          <span class="weight"
            >平台服务的具体内容由平台根据实际情况提供，例如信息发布、服务对接、业务洽谈等服务。
          </span>
        </div>
        <div>
          <div class="weight">四、平台使用</div>

          <span class="weight"
            >本平台信息及其任何组成部分不得被再造、复制、抄袭，或为任何未经本平台允许的商业目的所使用。
          </span>
          如果本平台确定客户行为违法或有损他人平台和企业的利益，则本平台和其关联企业将保留，包括但不限于拒绝提供服务、采取相关法律行为的权利。
        </div>
        <div>
          <div class="weight">五、发布内容</div>
          <div>
            您发布的内容应遵循以下原则: <br />
            1、遵守中国有关的法律和法规；<br />
            2、遵守所有与网络服务有关的网络协议、规定和程序；<br />
            3、不得为任何非法目的而使用网络服务系统；<br />
            4、不得利用“青企链”平台服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；<br />
            5、不得利用“青企链”平台提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；<br />
            6、不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；除相关著作权人明示同意，不应在网络中擅自破解、传播、下载或复制第三人享有著作权的软件或进行其他任何侵害他人知识产权的活动。<br />
            7、不应擅自复制和使用网络上未公开和未授权的文件；<br />
            8、不得利用“青企链”或者相关服务危害国家安全、破坏政治稳定、泄露国家秘密，不侵犯国家、社会、集体利益和第三方的合法权益，不从事违法犯罪活动，不利用网络发布涉及实施诈骗，制作或者销售违禁物品、管制物品、以及其他违法犯罪活动的信息。<br />
          </div>
        </div>

        <div>
          <div class="weight">六、链接</div>
          为了提供更完善的服务，本平台可能提供相关信息的链接服务，本平台上的链接服务可能直接进入其他站点，这些链接的站点不受本平台的控制，
          <span class="weight"
            >本平台对任何与本站链接平台的内容概不负责任。
          </span>
        </div>
        <div>
          <div class="weight">七、隐私保护</div>
          一）保护用户隐私是平台的一项基本政策，平台保证不对外公开或向第三方提供单个用户的注册资料及用户在使用服务时存储在平台的非公开内容，但下列情况除外：
          事先获得用户的明确授权；根据有关的法律法规要求；按照相关政府主管部门的要求；为维护社会公众的利益；为维护平台的合法权益。<br />
          （二）平台可能会与第三方合作向用户提供相关的服务，在此情况下，如该第三方同意承担与平台同等的保护用户隐私的责任，则平台有权将用户的注册资料等提供给该第三方。<br />
          （三）在不透露单个用户隐私资料的前提下，平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。<br />
        </div>
        <div>
          <div class="weight">八、知识产权</div>
          您理解并知悉，除另有书面声明以外，以下信息和内容的知识产权（包括但不限于专利权、著作权、商标权及商业秘密）归本平台所有：<br />
          1、
          在本平台相关服务中所提供的内容和信息（包括但不限于软件、技术、程序、网页、文字、图片、图像、地图、图标、音频、视频、图表、版面设计、电子文档、数据资料等）；<br />
          2、本平台用于提供上述内容和信息的基础设施和平台（包括但不限于软件、网站、应用程序等）；<br />
          3、在本平台供相关服务中所使用的各项商标、商业形象、商业标识、技术诀窍、标语、文案等；<br />
          4、本平台服务的开发、运营、维护等过程中产生的所有数据和信息。<br />
          5、除非本平台书面许可，您在使用本平台的软件时不得从事下列任一行为：<br />
          （1）删除、编辑或遮挡软件及其副本上关于著作权、商标或其他权利标识或标记的信息；<br />
          （2）复制、发布、出售或出租软件或其任何部分；<br />
          （3）对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试挖掘、提取软件的源代码；<br />
          （4）
          其他对软件、软件所产生的数据所进行的任何增加、删减、变动的任何行为，包含制作、利用、授权各种第三方外挂、插件、系统进行上述行为。<br />
          本平台所拥有的上述权利及所提供服务中所包含的任何内容的知识产权均受到法律保护，未经本平台事先书面许可，您承诺不应且不应允许或协助任何人以任何形式（包括但不限于通过任何机器人、蜘蛛、截屏等程序或设备）进行使用、出租、出借、分发、展示、复制、修改、链接、转载、汇编、发表、出版、抓取、监视、引用或创造相关衍生作品。<br />
        </div>
        <div>
          <div class="weight">九、免责声明</div>
          <div class="weight">
            所有在本平台发布的信息均由其发布人发布。因此，该信息的合法性和真实性由发布者负责。本平台对任何损失或任何由于使用本平台而引起的损失，包括但不限于直接的、间接的、偶然的、惩罚性的和引发的损失，不承担任何责任。
          </div>
        </div>
        <div>
          <div class="weight">十、责任承担</div>
          <div class="weight">
            如您在使用“青企链”服务过程中涉及上述行为中的一项或多项，则需要对自己的行为承担法律责任。承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在本平台首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任等损失（含诉讼费、律师费等相关法律程序费用）后，您应立即给予本平台等额的赔偿。此外，根据具体违法违规情形，本平台有权作出独立判断，立即暂停或终止对您提供部分或全部服务，包括锁定、注销、删除帐号等措施。
          </div>
        </div>
        <div>
          <div class="weight">十一、不可抗力和其他免责事由</div>
          （一）不可抗力。您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务协议下的服务发生中断或终止。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于信息网络设备维护、信息网络连接故障、电脑、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、政府行为、法律法规变动、司法行政机关的命令、其他不可抗力或第三方的不作为而造成的不能服务或延迟服务等行为。出现上述情况时，本平台将努力在第一时间与相关部门配合，及时进行修复，但是由此给您造成的损失，本平台在法律允许的范围内免责。<br />
          （二）其他免责事由。您理解并同意，在符合法律规定的情况下，本平台对以下事由所导致的服务中断或终止不承担责任：<br />
          （1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；<br />
          （2）用户或本平台的电脑软件、系统、硬件和通信线路出现故障；<br />
          （3）用户操作不当；<br />
          （4）用户通过非美团授权的方式使用本服务；<br />
          在使用本服务的过程中，您可能会遇到网络信息或其他用户行为带来的风险，该等风险包含他人匿名或冒名的含有威胁、诽谤、令人反感、非法、误导、欺骗等任何信息或行为所造成任何心理、生理上的伤害或经济损失。请您仔细甄别，并在遇到上述相关不法行为时及时向本平台或有关机关举报或投诉。本平台将对相关内容开展日常检查并在收到举报或投诉时进行相应处理，<br />
          <span class="weight"
            >但请您注意，本平台不对非本平台所发出或刊登的任何信息的真实性、适用性、合法性承担责任，也不对因第三方侵权行为给您造成的损害负责。</span
          >
        </div>
        <div>
          <div class="weight">十二、通知与送达</div>
          （您知悉并认可，本平台可视情况通过下列任意一种或几种方式向您通知重要信息：<br />
          （1）向您注册时所提交的电子邮箱地址发送电子信息；<br />
          （2）向您注册时所提交的手机号码发送电子信息；<br />
          （3）向您提供的实际地址进行寄送纸质载体信息；<br />
          （4）在网站或客户端刊载电子信息；<br />
          （5）向本平台网站或相应客户端的账户的站内信或其他即时通信客户端发送电子信息。<br />
          上述电子信息在发送成功或刊登完成后即视为送达。相关纸质载体的发送以相关邮寄凭证上的邮寄日期后的第五个自然日视为送达。<br />
          上述送达方式同样可适用于相关仲裁或司法程序（含起诉、审理、执行等各阶段）。<br />
          您应当保证所提供的联系方式是准确、有效的，并进行适时更新，如因提供的联系方式不准确或怠于更新等不可归责于本平台的原因，导致相关通知、文件、文书无法送达或及时送达，您将自行承担由此可能产生的法律后果。<br />
        </div>
        <div>
          <div class="weight">十三、争议解决</div>
          （本服务协议的成立、生效、履行、解释与纠纷解决，适用中华人民共和国大陆地区法律法规，并且排除一切冲突法规定的适用。<br />
          如因某项具体服务中的产品或服务问题导致您与本平台间出现纠纷，您同意，该等纠纷将由适用于该项具体服务的服务条款或规则中所规定的争议解决地的有管辖权人民法院受理。<br />
          <span class="weight">
            就本服务协议而言，如您因本服务协议文本与本平台产生争议纠纷，您同意交由所本平台有权人所在地有管辖权人民法院受理。</span
          >
        </div>
        <div>
          <div class="weight">
            十四、您只有无条件接受以上所有服务条款，才能继续使用“青企链”平台的各项服务。
          </div>
        </div>
        <div>
          <div class="weight">
            十五、“青企链”平台所有权归青海省中小企业公共服务平台（青海经信中小企业服务有限公司）所有。本声明最终解释权、修改权归青海省中小企业公共服务平台（青海经信中小企业服务有限公司）。
          </div>
        </div>
        <div>
          <div class="weight">十六、本服务声明生效时间：2022年11月16日</div>
        </div>
      </div>
      <div>
        <div>
          <div class="flex" style="margin-top: 30px">
            <div
              style="
                width: 100vw;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: #ff6618;
                border-radius: 10px;
                color: #fff;
              "
              @click="releasefeedlog = false"
            >
              我已阅读并同意
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    checkeding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      releasefeedlog: false,
      checked: this.checkeding,
    };
  },
  created() {},

  methods: {
    changeParentName() {
      this.$emit("handleChange", this.checked);
    },
  },
};
</script>

<style scoped>
.useragreement {
  color: #43a8fe;
  font-family: Source Han Sans SC Regular, Source Han Sans SC Regular-Regular;
  font-weight: 400;
}
</style>